import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Navbar, Footer, ScrollProgressBar } from './components/Shared';
import { ErrorBoundary } from 'react-error-boundary';
import { FaExclamationTriangle } from 'react-icons/fa';
import { PopupModal } from 'react-calendly';
import A2Z1ProductLaunch from './components/a2z1launch';
import A2ZRadiologyAIOutOfStealth from './components/a2zstory';
import PrivacyPolicy from './components/PrivacyPolicy';
import NotFound from './components/NotFound';

const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: () => void;
}> = ({ error, resetErrorBoundary }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-light dark:bg-dark">
      <div className="bg-light dark:bg-secondary p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center mb-4">
          <FaExclamationTriangle className="text-red-500 text-3xl mr-3" />
          <h2 className="text-2xl font-bold text-dark dark:text-light">
            Oops! Something went wrong
          </h2>
        </div>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          We're sorry, but an error occurred while processing your request.
        </p>
        <div className="bg-red-100 dark:bg-red-900 border-l-4 border-red-500 dark:border-red-700 text-red-700 dark:text-red-300 p-4 mb-4">
          <p className="font-bold">Error details:</p>
          <p className="text-sm">{error.message}</p>
        </div>
        <button
          onClick={resetErrorBoundary}
          className="bg-primary hover:bg-primary-dark dark:bg-primary dark:hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition-colors duration-200"
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    return true;
  });

  // Add Calendly state and functions
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const openCalendly = () => setIsCalendlyOpen(true);
  const closeCalendly = () => setIsCalendlyOpen(false);

  useEffect(() => {
    // Apply the theme class to the root element
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={isDarkMode ? 'dark' : ''}>
        <Navbar openCalendly={openCalendly} />
        <ScrollProgressBar />
        <Routes>
          <Route path="/" element={<Navigate to="/news/a2z1-product-launch" replace />} />
          <Route path="/news/a2z1-product-launch" element={<A2Z1ProductLaunch openCalendly={openCalendly} />} />
          <Route path="/story" element={<Navigate to="/news/a2z-radiology-ai-out-of-stealth" replace />} />
          <Route
            path="/news/a2z-radiology-ai-out-of-stealth"
            element={<A2ZRadiologyAIOutOfStealth />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer isDarkMode={isDarkMode} toggleTheme={toggleTheme} openCalendly={openCalendly} />

        {/* Calendly Popup Modal */}
        <PopupModal
          url="https://calendly.com/d/cmg5-cyr-q57"
          onModalClose={closeCalendly}
          open={isCalendlyOpen}
          rootElement={document.getElementById('root') as HTMLElement}
        />
      </div>
    </ErrorBoundary>
  );
};

export default App;