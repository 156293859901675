// src/components/ProductLaunch.tsx
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  AlertTriangle,
  EyeOff,
  HeartOff,
  HeartPulse,
  Users,
  BrainCircuit,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInitializeCases } from '../hooks/useInitializeCases';
import CaseViewer from './CaseViewer';
import {
  ConditionPerformanceChart,
  PerformanceComparisonChart,
  SubgroupChart,
} from './Charts';
// Add import for shared components
import { ShinyButton, SidebarNav } from './Shared';
import { Element } from 'react-scroll';

// Text Content
const content = {
  hero: {
    title: "Announcing a2z-1: The AI Safety Net for Radiology Excellence",
    subtitle: "AI-powered second look for abdomen-pelvis CT scans.",
    buttonText: "Book a Demo"
  },
  impact: {
    title: "Real-World Impact: How a2z-1 Makes a Difference",
    description: "a2z-1 demonstrates its value in enhancing quality by identifying abnormal findings that benefit from additional review:",
    points: [
      {
        title: "Spotting Subtle Abnormalities",
        description: "a2z-1 flagged potential air in the abdomen (81.84% confidence) in cases where the original report didn't mention this finding, prompting valuable second looks and potentially improving patient outcomes.",
      },
      {
        title: "Encouraging Report Precision",
        description: "The AI suggested possible gallbladder inflammation (97.76% confidence) in cases with less specific original terminology, promoting more precise documentation and potentially affecting treatment decisions.",
      },
      {
        title: "Noting Incidental Findings",
        description: "a2z-1 consistently observed signs of coronary artery calcification (69.62% confidence) in scans where only \"scattered plaque\" was mentioned, supporting more comprehensive reporting and potential follow-ups.",
      }
    ]
  },
  howItWorks: {
    title: "How a2z-1 Works",
    description: "A Practical Approach to Radiology Support:",
    steps: [
      {
        title: "Post-Report Review",
        description: "After a radiologist finalizes a report, a2z-1 independently examines both the CT images and the corresponding report.",
      },
      {
        title: "AI-Powered Analysis",
        description: "Our advanced AI checks for discrepancies across 21 actionable abdominal conditions, comparing imaging findings with the report to identify potential overlooked details.",
      },
      {
        title: "Safety Net Alert",
        description: "If significant discrepancies are detected, a2z-1 alerts the radiology team, enabling timely review and potential report refinement.",
      }
    ]
  },
  validation: {
    title: "Scientifically Validated and Trusted",
    points: [
      {
        title: "High Performance Across Actionable Conditions",
        description: "a2z-1 consistently identifies 21 actionable abdominal conditions with high accuracy, serving as a reliable second reader for radiologists.",
      },
      {
        title: "Multi-System Validation",
        description: "Extensive testing across various health systems confirms a2z-1's robust and consistent performance in diverse clinical environments.",
      },
      {
        title: "Adaptable to Various Practice Settings",
        description: "a2z-1 maintains high accuracy across diverse patient groups and imaging protocols, making it a versatile ally for a wide range of radiology practices.",
      }
    ]
  },
  takeNextStep: {
    title: "Enhance Your Radiology Practice",
    description: "Discover how a2z-1 can transform your radiology department by enhancing quality. With minimal impact on radiologist workload and a focus on practical support, a2z-1 enables you to prioritize patient outcomes by streamlining quality assurance processes. Our system allows for meaningful insights within your facility.",
    userTypes: [
      {
        title: "Radiology Department Leader",
        description: "Looking to implement an advanced, AI-powered support system to meet professional standards and improve overall accuracy.",
      },
      {
        title: "Radiologist",
        description: "Seeking a reliable 'second set of eyes' to support your clinical decision-making with minimal additional workload.",
      },
      {
        title: "Healthcare Administrator",
        description: "Aiming to improve patient outcomes and reduce risks through a practical, easily implemented radiology support system.",
      }
    ],
    buttonText: "Request a Demo"
  }
};

// Hero Section Component (Updated for Responsiveness)
const HeroSection = ({ openCalendly }: { openCalendly: () => void }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <section className="bg-light dark:bg-dark relative overflow-hidden flex items-center py-8 px-4 sm:px-6 lg:px-8" id="home">
      <div className="relative z-10 max-w-4xl mx-auto flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="w-full lg:w-1/2 lg:pr-8">
          <motion.h1
            className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 text-primary dark:text-accent text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {content.hero.title}
          </motion.h1>
          <motion.p
            className="text-lg sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {content.hero.subtitle}
          </motion.p>

          {/* Button Container */}
          <div className="flex justify-start">
            <ShinyButton onClick={openCalendly} className="sm:mx-0 mx-auto mb-4 lg:mb-0">
              {content.hero.buttonText}
            </ShinyButton>
          </div>
        </div>

        {/* Visual Content */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <motion.div
            className="relative w-full max-w-3xl mx-auto"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: isImageLoaded ? 1 : 0,
              scale: isImageLoaded ? 1 : 0.8,
            }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <img
              src="/playground_data/bbox_plots/EM9_pancreas_acute_pancreatitis.png"
              alt="CT Scan showing Acute Pancreatitis"
              className="rounded-lg shadow-2xl w-full object-contain-custom"
              onLoad={() => setIsImageLoaded(true)}
            />
            <motion.div
              className="absolute -bottom-4 -right-4 bg-white dark:bg-gray-800 p-3 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 1.2 }}
            >
              <p className="text-xs font-semibold text-gray-800 dark:text-gray-200">
                AI-detected: Acute Pancreatitis
              </p>
              <p className="text-xs text-gray-600 dark:text-gray-400">Confidence: High</p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Real-World Impact Section with CaseViewer
interface RealWorldImpactProps {
  cases: any; // Replace 'any' with actual type if available
  isLoading: boolean;
  error: any; // Replace 'any' with actual type if available
}

const RealWorldImpactSection: React.FC<RealWorldImpactProps> = ({ cases, isLoading, error }) => {
  const impactPoints = [
    {
      icon: AlertTriangle,
      title: 'Catching Subtle Abnormalities',
      description:
        'a2z-1 flagged potential air in the abdomen (81.84% confidence) in cases where the original report didn\'t mention this finding, prompting valuable second looks and potentially improving patient outcomes.',
    },
    {
      icon: EyeOff,
      title: 'Enhancing Report Specificity',
      description:
        'The AI suggested acute cholecystitis (97.76% confidence) in cases with less specific original terminology, encouraging more precise documentation and potentially affecting treatment decisions.',
    },
    {
      icon: HeartOff,
      title: 'Highlighting Incidental Findings',
      description:
        'a2z-1 consistently noted signs of coronary artery calcification (69.62% confidence) in scans where only "scattered plaque" was mentioned, promoting more comprehensive reporting and potential follow-ups.',
    },
  ];

  return (
    <section id="impact" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.impact.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-6 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.impact.description}
        </motion.p>
        <ul className="space-y-6">
          {impactPoints.map((item, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 + index * 0.2 }}
            >
              <item.icon className="mr-4 h-6 w-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">{item.title}</h3>
                <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
      {/* Full-Width CaseViewer with Max Width */}
      <div className="w-full flex justify-center px-4 sm:px-8">
        <div className="w-full max-w-3xl">
          <CaseViewer cases={cases} isLoading={isLoading} error={error} />
        </div>
      </div>
    </section>
  );
};

const HowA2z1WorksSection = () => {
  const steps = [
    {
      title: 'Post-Report Analysis',
      description:
        'After a radiologist finalizes a report, a2z-1 independently reviews both the CT images and the corresponding report.',
    },
    {
      title: 'AI-Powered Detection',
      description:
        'Our advanced AI analyzes for discrepancies across 21 actionable abdominal conditions, comparing imaging findings with the report to identify potential missed findings.',
    },
    {
      title: 'Quality Assurance Notification',
      description:
        'If significant discrepancies are detected, a2z-1 notifies a quality assurance team, enabling timely review and potential report refinement.',
    },
  ];

  // Define animation variants for the container and items with increased durations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Increased from 0.3 to 0.5 for slower stagger
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8, // Increased from 0.6 to 0.8 for slower item transition
      },
    },
  };

  return (
    <section id="how-it-works" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }} // Increased from 0.6 to 0.8
        >
          {content.howItWorks.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }} // Increased duration and adjusted delay
        >
          {content.howItWorks.description}
        </motion.p>
        {/* Workflow Steps */}
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex-1 flex items-start"
              variants={itemVariants}
            >
              {/* Numbered Badge */}
              <div className="relative mr-4">
                <div className="flex items-center justify-center h-10 w-10 bg-primary text-white rounded-full text-lg font-bold">
                  {index + 1}
                </div>
              </div>
              {/* Step Details */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">{step.title}</h3>
                <p className="text-gray-700 dark:text-gray-300">{step.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// Scientific Validation and Credibility Section with Tabbed Interface
const ScientificValidationSection = () => {
  const validationPoints = [
    {
      title: 'High Performance Across Actionable Conditions',
      description:
        'a2z-1 consistently identifies 21 actionable abdominal conditions with high accuracy, serving as a reliable quality assurance tool for radiologists.',
      icon: HeartPulse,
    },
    {
      title: 'Multi-System Validation',
      description:
        'Extensive testing across various health systems confirms a2z-1\'s robust and consistent performance in diverse clinical environments.',
      icon: AlertTriangle,
    },
    {
      title: 'Adaptable to Various Practice Settings',
      description:
        'a2z-1 maintains high accuracy across diverse patient groups and imaging protocols, making it suitable for a wide range of radiology practices.',
      icon: Users,
    },
  ];

  // Define the available tabs
  const tabs = [
    { label: 'Condition Performance', component: <ConditionPerformanceChart /> },
    { label: 'Performance Comparison', component: <PerformanceComparisonChart /> },
    { label: 'Subgroup Analysis', component: <SubgroupChart /> },
  ];

  // State to manage the active tab index
  const [activeTab, setActiveTab] = useState(0);

  return (
    <section id="validation" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.validation.title}
        </motion.h2>
        <div className="space-y-8">
          {validationPoints.map((item, index) => (
            <motion.div
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <item.icon className="mr-4 h-8 w-8 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">{item.title}</h3>
                <p className="text-gray-700 dark:text-gray-300">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Tabbed Interface */}
        <div className="mt-12">
          {/* Tab Headers */}
          <div className="flex border-b border-gray-300 dark:border-gray-700">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-2 px-4 -mb-px font-semibold text-sm transition-colors duration-300 focus:outline-none ${
                  activeTab === index
                    ? 'border-b-2 border-primary text-primary'
                    : 'text-gray-600 dark:text-gray-300 hover:text-primary'
                }`}
                onClick={() => setActiveTab(index)}
                aria-selected={activeTab === index}
                role="tab"
                aria-controls={`tabpanel-${index}`}
                id={`tab-${index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab Panels */}
          <div className="mt-4">
            {tabs.map((tab, index) => (
              <AnimatePresence key={index}>
                {activeTab === index && (
                  <motion.div
                    id={`tabpanel-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    {tab.component}
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// Take the Next Step Section (Updated)
const TakeNextStepSection = ({ openCalendly }: { openCalendly: () => void }) => (
  <section id="take-the-next-step" className="bg-light dark:bg-dark">
    <div className="py-16 px-4 sm:px-8 max-w-4xl mx-auto text-left">
      <motion.h2
        className="text-4xl sm:text-5xl font-extrabold mb-6 text-gray-900 dark:text-gray-200"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        {content.takeNextStep.title}
      </motion.h2>
      <motion.p
        className="text-lg sm:text-2xl mb-12 text-gray-700 dark:text-gray-300"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {content.takeNextStep.description}
      </motion.p>
      {/* Upgraded "If you're a:" Section */}
      <div className="mb-8">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-4">
          If you're a:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <Users className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">{content.takeNextStep.userTypes[0].title}</h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[0].description}
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <BrainCircuit className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">{content.takeNextStep.userTypes[1].title}</h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[1].description}
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <AlertTriangle className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">{content.takeNextStep.userTypes[2].title}</h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[2].description}
            </p>
          </div>
        </div>
      </div>
            {/* Centered Button */}
            <motion.div
        className="flex justify-center mb-12"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <ShinyButton onClick={openCalendly}>{content.takeNextStep.buttonText}</ShinyButton>
      </motion.div>
    </div>
  </section>
);

// Main Product Launch Component
interface A2z1launchProps {
  openCalendly: () => void;
}

const A2z1launch: React.FC<A2z1launchProps> = ({ openCalendly }) => {
  // Remove the Calendly state management from this component
  const { cases, isLoading, error } = useInitializeCases({
    casesUrl: '/playground_data/cases.json',
    commentariesUrl: '/playground_data/commentaries.json',
    imagesPath: '/playground_data/bbox_plots',
  });

  // Define navItems to pass to SidebarNav
  const navItems = [
    { name: 'How It Works', to: 'how-it-works' },
    { name: 'Impact', to: 'impact' },
    { name: 'Validation', to: 'validation' },
    { name: 'Connect', to: 'take-the-next-step' },
  ];

  return (
    <div className="bg-light text-gray-900 dark:bg-dark dark:text-gray-300 min-h-screen transition-colors duration-300">
      <Helmet>
        <title>{content.hero.title}</title>
        <meta name="description" content={content.hero.subtitle} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={content.hero.title} />
        <meta property="og:description" content={content.hero.subtitle} />
        <meta property="og:image" content="/og-image.png" />
        <meta property="og:url" content="https://a2zradiology.ai/news/a2z1-product-launch" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={content.hero.title} />
        <meta name="twitter:description" content={content.hero.subtitle} />
        <meta name="twitter:image" content="/og-image.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://a2zradiology.ai/news/a2z1-product-launch" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${content.hero.title}",
              "description": "${content.hero.subtitle}",
              "url": "https://a2zradiology.ai/news/a2z1-product-launch",
              "image": "/og-image.png",
              "publisher": {
                "@type": "Organization",
                "name": "a2z Radiology AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "/a2z-logo-square-500px.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      {/* SidebarNav */}
      <SidebarNav sections={navItems} />

      {/* Main Content Area with adjusted margin */}
      <div className="lg:ml-16">
        <Element name="home">
          <HeroSection openCalendly={openCalendly} />
        </Element>

        <Element name="how-it-works">
          <HowA2z1WorksSection />
        </Element>

        <Element name="impact">
          <RealWorldImpactSection cases={cases} isLoading={isLoading} error={error} />
        </Element>

        <Element name="validation">
          <ScientificValidationSection />
        </Element>

        <Element name="take-the-next-step">
          <TakeNextStepSection openCalendly={openCalendly} />
        </Element>
      </div>
    </div>
  );
};

export default A2z1launch;