import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const A2zstory: React.FC = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-dark text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>Launching a2z Radiology AI: Vision for a World Where no Disease is Missed</title>
        <meta
          name="description"
          content="a2z Radiology AI emerges from stealth, introducing AI-powered safety net for radiologists with a2z-1 for abdominal-pelvis CT interpretation."
        />
        <meta property="og:title" content="Launching a2z Radiology AI: Vision for a World Where no Disease is Missed" />
        <meta
          property="og:description"
          content="a2z Radiology AI emerges from stealth, introducing AI-powered safety net for radiologists with a2z-1 for abdominal-pelvis CT interpretation."
        />
        <meta property="og:image" content="/og-image.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Launching a2z Radiology AI: Vision for a World Where no Disease is Missed" />
        <meta
          name="twitter:description"
          content="a2z Radiology AI emerges from stealth, introducing AI-powered safety net for radiologists with a2z-1 for abdominal-pelvis CT interpretation."
        />
        <meta name="twitter:image" content="/og-image.png" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "headline": "Launching a2z Radiology AI: Vision for a World Where no Disease is Missed",
              "image": [
                "/og-image.png"
              ],
              "datePublished": "2024-10-07T13:00:00-04:00",
              "dateModified": "2024-10-07T13:00:00-04:00",
              "author": [{
                "@type": "Person",
                "name": "Pranav Rajpurkar",
                "url": "https://www.linkedin.com/in/pranavrajpurkar/"
              },{
                "@type": "Person",
                "name": "Samir Rajpurkar",
                "url": "https://www.linkedin.com/in/samir-rajpurkar-79291396/"
              }],
              "publisher": {
                "@type": "Organization",
                "name": "a2z Radiology AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "/a2z-logo-square-500px.png"
                }
              },
              "description": "a2z Radiology AI emerges from stealth, introducing AI-powered safety net for radiologists with a2z-1 for abdominal-pelvis CT interpretation."
            }
          `}
        </script>
      </Helmet>

      <main className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Launching a2z Radiology AI: Vision for a World Where no Disease is Missed
        </motion.h1>

        <motion.div
          className="flex items-center space-x-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {/* Author Avatars */}
          <img
            src="/PranavRajpurkar.jpg"
            alt="Pranav Rajpurkar"
            className="w-10 h-10 rounded-full object-cover"
          />
          <img
            src="/SamirRajpurkar.jpg"
            alt="Samir Rajpurkar"
            className="w-10 h-10 rounded-full object-cover"
          />

          {/* Author Names and Date */}
          <div>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              By{' '}
              <a
                href="https://www.linkedin.com/in/pranavrajpurkar/"
                className="text-primary dark:text-accent hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pranav Rajpurkar
              </a>{' '}
              &{' '}
              <a
                href="https://www.linkedin.com/in/samir-rajpurkar-79291396/"
                className="text-primary dark:text-accent hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Samir Rajpurkar
              </a>{' '}
              on October 7, 2024 at 1:00 PM ET
            </p>
          </div>
        </motion.div>

        <motion.div
          className="text-md sm:text-xl space-y-6 text-gray-800 dark:text-gray-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p>
            Today, <strong>a2z Radiology AI</strong> emerges from stealth mode, introducing our mission to create an AI-powered safety net for radiologists. The name <strong>"a2z"</strong> reflects our commitment to detecting everything from <em>A to Z</em>, ensuring that nothing is missed. We're launching <strong>a2z-1</strong>, our first product designed to enhance quality assurance in abdominal-pelvis CT interpretation.
          </p>
          <p>
            We founded a2z Radiology AI this year with a vision to develop AI that serves as a reliable ally for radiologists, identifying potential oversights without disrupting established workflows. This goal, shaped by years of collaboration with radiologists and experience in technology, has driven our work during our stealth period.
          </p>
          <p>
            Our launch comes at a pivotal moment in the field of radiology AI. Recent advancements in AI have opened new possibilities for scalable, multi-disease detection in radiology image interpretation. We've utilized these advancements to build custom AI models that can simultaneously analyze multiple conditions, a capability that distinguishes a2z-1 in the landscape of radiology AI.
          </p>
          <p>
            Notably, a2z-1 is the first of its kind in the history of AI in radiology to tackle multi-disease abdomen-pelvis CT interpretation. This exam type is one of the most common in radiology practices, yet it has long challenged AI due to its complexity and the wide range of potential findings. By focusing on this critical area, we're addressing a significant need in the field and pushing the boundaries with cutting-edge AI.
          </p>
          <p>
            Our focus with a2z-1 on quality assurance (QA) represents a strategic choice to maximize near-term impact. By enhancing the QA process, we're able to add significant value without altering the day-to-day practice of radiologists. a2z-1 operates in the background, analyzing completed reports and images to flag potential discrepancies across a wide range of conditions. It functions as an additional layer of review, highlighting possible oversights without interfering with the original interpretation.
          </p>
          <p>
          During our stealth phase, we've collaborated extensively with radiologists and early partners to develop a solution that prioritizes real-world performance. We've validated a2z-1 across three distinct health systems, conducted thorough subgroup analyses, and optimized our alert system to maximize value while minimizing alert fatigue. Our innovative approach to model training and annotation has allowed us to create a tool that's not only conceptually promising but also robust and ready for immediate implementation.
          </p>
          <p>
            While a2z-1 marks our public debut, it's only the beginning. We're already developing next-generation models to further enhance our safety net for abdominal-pelvis CT interpretation.
          </p>
          <p>
            As we make our work public, we're reaching out to forward-thinking health systems to join us in raising the standard of care. If you lead a radiology team and want to improve your quality assurance, reduce reporting variability, and foster continuous improvement, we invite you to connect with us.
          </p>
          <p>
            The time has come to create a safer, more reliable future where no disease is missed on imaging.
          </p>
        </motion.div>

        <motion.div
          className="mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.8 }}
        >
          <Link to="/" className="text-primary dark:text-accent hover:underline">
            &larr; Back to Home
          </Link>
        </motion.div>
      </main>
    </div>
  );
};

export default A2zstory;